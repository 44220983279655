<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Квалификационные требования</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="addQualityData"
        />
      </div>
    </div>
    <TableSlot v-bind="qualifications" @rowClick="showQualityDetails" />
  </div>
</template>

<script>
import { API_qualification_list } from "@/services/references/qualification/index.js";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";

export default {
  name: "qualificationRequirementList",
  components: { UIButton, TableSlot },
  data() {
    return {
      qualifications: {
        columns: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "qualificationID",
          },
        ],
        data: [],
        filters: {
          "WorkIdentifiedObject.IdentifiedObject.name": "",
          qualificationID: "",
        },
      },
    };
  },
  mounted() {
    API_qualification_list().then((res) => {
      this.qualifications.data = res;
    });
  },
  methods: {
    showQualityDetails(item) {
      this.$router.push({
        path: `/references/qualification-requirement/${item.id}`,
      });
    },
    addQualityData() {
      this.$router.push({
        path: `/references/qualification-requirement/add`,
      });
    },
  },
};
</script>

<style scoped></style>
